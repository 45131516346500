import React, { useState, useEffect } from "react";
import moment from "moment";
import { map } from "jquery";
import { orderBy } from "lodash";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";

import { Box, useToast } from "@chakra-ui/react";

import {
  getPlannerEventRequestListAction,
  getPlannerScheduleAction,
} from "../../redux/reducers/planner";

import { STATUS } from "../../App/Constants";
import { startMentorshipSessionAction } from "../../redux/reducers/mentorship";

export default function MentorshipClasses({ selectedDate, currentTeacherId }) {
  const [cookies, setCookie, removeCookie] = useCookies();
  const toast = useToast();
  const dispatch = useDispatch();
  const [events, setEvents] = useState();
  const [currentDateEvents, setCurrentDateEvents] = useState();
  const [filterEvent, setFilterEvent] = useState([]);
  const [staffId, setStaffId] = useState("");
  // const [selectedEventData, setSelectedEventData] = useState({});

  const {
    user,
    activeStaffsList,
    mentorEventsList,
    plannerRequestList,
    plannerScheduleList,
    updatePlannerEventStatus,
    updatePlannerEventRescheduleStatus,
    deletePlannerEventRequestListStatus,
    getPlannerScheduleListStatus,
  } = useSelector((s) => ({
    user: s.user.user,
    mentorEventsList: s.mentorship.mentorEventsList,
    activeStaffsList: s.planner.activeStaffsList,
    plannerRequestList: s.planner.plannerRequestList,
    plannerScheduleList: s.planner.plannerScheduleList,
    updatePlannerEventStatus: s.planner.updatePlannerEventStatus,
    getPlannerScheduleListStatus: s.planner.getPlannerScheduleListStatus,
    deletePlannerEventRequestListStatus:
      s.planner.deletePlannerEventRequestListStatus,
    updatePlannerEventRescheduleStatus:
      s.planner.updatePlannerEventRescheduleStatus,
  }));

  useEffect(() => {
    if (selectedDate) {
      if (mentorEventsList && mentorEventsList.length > 0) {
        setEvents(mentorEventsList);
      } else {
        setEvents([]);
      }

      const packageFilter = document.getElementById("packageFilter");
      if (packageFilter) {
        packageFilter.value = "";
      }

      // dispatch(getStaffsListAction({ id: "65b51fbc2ff7b4790f01e91e" }));
    }
  }, [selectedDate, mentorEventsList]);

  useEffect(() => {
    if (events && events.length > 0) {
      const selectedEvents = events.filter(
        (event) =>
          moment(selectedDate).format("YYYY-MM-DD") ===
          moment(event.startDate).format("YYYY-MM-DD")
      );
      setCurrentDateEvents(selectedEvents);
    } else {
      setCurrentDateEvents([]);
    }
  }, [events, selectedDate]);

  useEffect(() => {
    if (currentDateEvents?.length > 0) {
      const sortedEvents = orderBy(
        currentDateEvents,
        [(event) => moment(event?.startDate).toDate()],
        ["asc"]
      );
      setFilterEvent(sortedEvents);
    } else {
      setFilterEvent([]);
    }
  }, [currentDateEvents]);

  useEffect(() => {
    if (updatePlannerEventStatus === STATUS.SUCCESS) {
      setShowRescheduleModal(false);
      setShowSwapClassModal(false);
      setShowCancelModal(false);
      dispatch(
        getPlannerScheduleAction({
          staffIds: [user?.staff?._id],
          date: moment(selectedDate).format("YYYY-MM-DD"),
        })
      );
      // dispatch(getUserScheduleAction({ teacherId: user?._id }));
    }
  }, [updatePlannerEventStatus]);

  useEffect(() => {
    if (getPlannerScheduleListStatus === STATUS.SUCCESS) {
      dispatch(getPlannerEventRequestListAction());
    }
  }, [getPlannerScheduleListStatus]);

  useEffect(() => {
    if (deletePlannerEventRequestListStatus === STATUS.SUCCESS) {
      dispatch(getPlannerEventRequestListAction());
      dispatch(
        getPlannerScheduleAction({
          staffIds: [user?.staff?._id],
          date: moment(selectedDate).format("YYYY-MM-DD"),
        })
      );
      // dispatch(getUserScheduleAction({ teacherId: user?._id }));
    } else if (updatePlannerEventRescheduleStatus === STATUS.SUCCESS) {
      dispatch(getPlannerEventRequestListAction());
      dispatch(
        getPlannerScheduleAction({
          staffIds: [user?.staff?._id],
          date: moment(selectedDate).format("YYYY-MM-DD"),
        })
      );
      // dispatch(getUserScheduleAction({ teacherId: user?._id }));
    }
  }, [deletePlannerEventRequestListStatus, updatePlannerEventRescheduleStatus]);

  const handleJoinSession = (selectedEvent) => {
    const currentDateTime = moment();
    const startDate = moment(selectedEvent?.startDate);
    const endDate = moment(selectedEvent?.endDate);
    const waitingTime = selectedEvent?.waitingDuration; //In minutes
    const startTimeWithWaiting = startDate
      .clone()
      .add(waitingTime * 60 * 1000, "milliseconds");
    const isWithinTimeRange = currentDateTime.isBetween(
      startDate,
      startTimeWithWaiting
    );

    const isBefore = moment().isBefore(moment(startDate), "minute");

    const isAfterWaitingDuration = moment().isAfter(
      moment(startDate).add(waitingTime, "minutes")
    );
    // setSelectedEventData(selectedEvent);
    if (isBefore) {
      toast.closeAll();
      toast({
        title: "Please wait for the session to start.",
        position: "top",
        variant: "subtle",
        status: "warning",
        duration: 2500,
        isClosable: false,
      });
      return;
    } else if (isAfterWaitingDuration) {
      toast.closeAll();
      toast({
        title: "Session join time is ended now.",
        position: "top",
        variant: "subtle",
        status: "warning",
        duration: 2500,
        isClosable: false,
      });
      return;
    } else if (isWithinTimeRange) {
      const formattedDateTime = currentDateTime.format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );
      // dispatch(setMentorshipCurrentSessionData(selectedEvent));

      dispatch(
        startMentorshipSessionAction({
          role: "teacher",
          id: selectedEvent?._id,
          date: formattedDateTime,
        })
      );
      setCookie("sessionId", selectedEvent?._id);
    }
  };

  return (
    <>
      <Box w="50%" px={[1, 1, 1, 5]}>
        <Box
          className="overflow-auto text-center events-heading-block"
          py={2}
          px={1}
          style={{ height: "max-content" }}
          borderRadius={5}
          boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
        >
          {" "}
          SESSIONS
        </Box>
        <Box>
          <div className="today-events-block">
            {filterEvent && filterEvent?.length > 0 ? (
              map(filterEvent, (currEvent, index) => {
                const startDate = moment(currEvent?.startDate);
                const endDate = moment(currEvent?.endDate);
                const currentDateTime = moment();
                const waitingTime = currEvent?.waitingDuration;
                const isAfterWaitingDuration = moment().isAfter(
                  moment(startDate).add(waitingTime, "minutes")
                );
                return (
                  <div className="event-card-block" key={index}>
                    <div className="event-card">
                      <div className="classes-card">
                        <div className="course-heading">
                          <h4> {currEvent?.subject?.subjectName} </h4>
                          <p>{currEvent?.chapter?.name?.en}</p>
                          <p>{"Mentorship"}</p>
                        </div>
                        {
                          <div className="status-tag">
                            <label> {currEvent?.status}</label>
                          </div>
                        }
                      </div>
                      <div className="date-time-sec">
                        <div className="date-sec">
                          <span>Assigned Date:</span>
                          <p>
                            {moment(currEvent?.startDate).format("DD-MMM-YYYY")}
                          </p>
                        </div>
                        <div className="time-sec">
                          <span>Assigned Time:</span>
                          <p>{`${moment(currEvent?.startDate).format(
                            "HH:mm"
                          )} - ${moment(currEvent?.endDate).format(
                            "HH:mm"
                          )}`}</p>
                        </div>
                      </div>
                      {currEvent?.status === "Upcoming" &&
                        !isAfterWaitingDuration && (
                          // isFuture &&
                          <div className="buttons-block ">
                            <div className="swap-but text-end w-100 d-block">
                              <button
                                type="button"
                                onClick={() => handleJoinSession(currEvent)}
                              >
                                Join Now
                              </button>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center"> No events available.</div>
            )}
          </div>
        </Box>
      </Box>
    </>
  );
}
