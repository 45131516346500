import { Button, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import React, { Fragment } from 'react'

const EditModal = ({
    isOpen,
    onClose,
    title,
    children,
    scrollBehavior = "inside",
    footer = true,
    onSubmit,
    size,
    width,
}) => {
    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            size={size}
            scrollBehavior={scrollBehavior}
            isCentered
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent className="schedule-class-popup">
                <form onSubmit={onSubmit}>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody maxH="400px" overflowY="auto">
                        <Fragment key="modal-content">
                            {children}
                        </Fragment>
                        {/* <Lorem count={15} /> */}
                    </ModalBody>
                    {footer &&
                        <ModalFooter>
                            <HStack spacing="15px">
                                <Button type="submit" colorScheme="blue" onClick={onSubmit}>Submit</Button>
                                <Button colorScheme="red" onClick={onClose}>Close</Button>
                            </HStack>
                        </ModalFooter>
                    }
                </form>
            </ModalContent>
        </Modal>
    )
}

export default EditModal