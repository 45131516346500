import React from 'react';
import Modal from 'react-modal';
import { Box, Button, FormControl, FormLabel, Text, VStack, Divider } from '@chakra-ui/react';

// Ensure you call Modal.setAppElement() in your main entry file (e.g., index.js)
Modal.setAppElement('#root'); // Adjust the selector to your app's root element

const ViewButtonModule = ({ isOpen, onRequestClose, students, teachers }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            ariaHideApp={false}
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    transform: 'translate(-50%, -50%)',
                    padding: '20px',
                    width: '80%',
                    maxWidth: '600px',
                },
            }}
        >
            <Box p={4}>
                <Text fontSize="lg" fontWeight="bold" mb={4}>
                    View Details
                </Text>
                <Box mb={6}>
                    <Text fontSize="md" fontWeight="bold" mb={2}>
                        Status
                    </Text>
                    
                </Box>
              

                {/* Teacher List */}
                <Box mb={6}>
                    <Text fontSize="md" fontWeight="bold" mb={2}>
                        Teacher
                    </Text>
                    <VStack spacing={2} align="start">
                        {teachers.length > 0 ? (
                            teachers.map((teacher) => (
                                <Box key={teacher.id} p={2} border="1px solid" borderColor="gray.200" borderRadius="md">
                                    {teacher.name}
                                </Box>
                            ))
                        ) : (
                            <Text>No teachers available</Text>
                        )}
                    </VStack>
                </Box>

                {/* Student List */}
                <Box>
                    <Text fontSize="md" fontWeight="bold" mb={2}>
                        Student
                    </Text>
                    <VStack spacing={2} align="start">
                        {students.length > 0 ? (
                            students.map((student) => (
                                <Box key={student.id} p={2} border="1px solid" borderColor="gray.200" borderRadius="md">
                                    {student.name}
                                </Box>
                            ))
                        ) : (
                            <Text>No students available</Text>
                        )}
                    </VStack>
                </Box>
            </Box>
            <Box p={4} textAlign="right">
                <Button onClick={onRequestClose} colorScheme="blue">
                    Close
                </Button>
            </Box>
        </Modal>
    );
};

export default ViewButtonModule;
