import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { GrPowerReset } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AiOutlineAudio, AiOutlineClear, AiOutlineFileProtect, AiOutlineVideoCamera } from "react-icons/ai";

import moment from "moment";
import _, { map, size } from "lodash";
import Pagination from "@choc-ui/paginator";

import "firebase/database";
import "firebase/messaging";
import firebase from "firebase/app";
import { firebaseClient } from "../../firebase";
import { DateRangePicker } from 'rsuite';

import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    HStack,
    IconButton,
    Tag,
    Text,
    useDisclosure,
    List,
    ListItem,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Input,
    InputLeftElement,
    InputGroup,
    VStack,
    Tooltip,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Tabs,
    TabList,
    Tab,
    TabPanel,
    TabPanels,
    Divider,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    Avatar,
    Select,
    InputRightElement,
} from "@chakra-ui/react";
import {
    ArrowDownIcon,
    ArrowUpIcon,
    ChevronRightIcon,
    CloseIcon,
    Search2Icon,
    ChevronDownIcon,
    PhoneIcon,
    CopyIcon,
    CheckCircleIcon
} from "@chakra-ui/icons";
import { Box, Heading } from "@chakra-ui/layout";


import {
    closeDoubtAction,
    getAllTeachers,
    getBatchSubjectStatusAction,
    getChatDetailsAction,
    getDoubtsAction,
    getTeacherAssingedAction,
    getTeacherDoubtsAction,
    publicDoubtAction,
    readCommentAction,
    resetReadAction,
    resetTeacherAssignedData,
} from "../../redux/reducers/doubt";

import { STATUS } from "../../Constants";
import { bilingualText, mobileView, webView } from "../../utils/Helper";

import { TeacherTests } from "../TeacherTests";
import { TeacherTests2 } from "../TeacherTests2";
import { TeacherCourses } from "../TeacherCourses";

import { ErrorChecker } from "../../Components/ErrorChecker";

import { DiscussionComments } from "../../routes/DiscussionCommunity/DiscussionComments";


export const TeacherChatScreen = (props) => {
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const { isOpen, onOpen, onToggle, onClose } = useDisclosure();

    const [active, setActive] = useState();
    const [formKey, changeFormKey] = useState(0);
    const [currentTab, setCurrentTab] = useState("0");
    const [searchDoubt, changeSearchDoubt] = useState();
    const [currentTeacher, setCurrentTeacher] = useState();
    const [testDiscussion, openTestDiscussion] = useState();
    const [courseDiscussion, openCourseDiscussion] = useState();


    const { user, chatDetails, allTeachers, teacherDoubts, closeDoubtStatus, getBatchSubjectLectureStatus } = useSelector((s) => ({
        user: s.user.user,
        // pageConfig: s.doubts.pageConfig,
        chatDetails: s.doubts.chatDetails,
        allTeachers: s.doubts.allTeachers,
        teacherDoubts: s.doubts.teacherDoubts,
        closeDoubtStatus: s.doubts.closeDoubtStatus,
        // getChatDetailsStatus: s.doubts.getChatDetailsStatus,
        // teacherAssignedData: s.doubts.teacherAssignedData || [],
        getBatchSubjectLectureStatus: s.doubts.getBatchSubjectLectureStatus,
    }));


    useEffect(() => {
        dispatch(getChatDetailsAction({ teacherId: user?._id }));
    }, []);

    useEffect(() => {
        if (getBatchSubjectLectureStatus === "SUCCESS") {
            dispatch(getChatDetailsAction({ teacherId: user?._id }));
        }
    }, [getBatchSubjectLectureStatus]);

    useEffect(() => {
        if (allTeachers?.length && params.staffId) {
            const staff = _.find(allTeachers, (t) => t.user?._id === params.staffId);
            setCurrentTeacher(staff);
        }
    }, [allTeachers, params]);

    const checkRole = useMemo(() => {
        return (
            user?.staff?.staffRole === "ADMIN" || user?.staff?.staffRole === "HEAD_TEACHER"
            // user?.staff?.staffRole === "TEACHER"
        );
    }, [user?.staff?.staffRole]);

    useEffect(() => {
        if (params?.staffId) dispatch(getTeacherAssingedAction({ assignedTo: params.staffId }));
        else if (user?.staff) dispatch(getTeacherAssingedAction({ assignedTo: user._id }));
        else dispatch(resetTeacherAssignedData());
    }, [teacherDoubts, dispatch, user, params]);

    // useEffect(() => {
    //   if(currentTeacher?.user)
    //     dispatch(getTeacherAssingedAction({assignedTo:currentTeacher.user._id}))
    // },[currentTeacher, dispatch])

    const getDoubts = useCallback(() => {
        if (params.page)
            if (params.staffId) {
                dispatch(getTeacherDoubtsAction({ page: params.page, staff: params.staffId, doubt_text_regex: searchDoubt }));
            } else dispatch(getTeacherDoubtsAction({ page: params.page, staff: user?._id, doubt_text_regex: searchDoubt }));
    }, [params.page, params.staffId, dispatch, searchDoubt, user?._id]);

    const getTeachers = useCallback(() => {
        if (checkRole) dispatch(getAllTeachers());
    }, [dispatch, checkRole]);

    useEffect(() => {
        getDoubts();
    }, [getDoubts]);

    useEffect(() => {
        getTeachers();
    }, [getTeachers]);

    // useEffect(() => {
    //   if (!params.page) {
    //     if (checkRole && allTeachers?.length)
    //       history.push("/doubts/1/" + allTeachers[0].user._id);
    //     else history.push("/doubts/1");
    //   }
    // });

    useEffect(() => {
        if (closeDoubtStatus === STATUS.SUCCESS && active) {
            setActive(_.find(teacherDoubts, (d) => d._id === active._id));
        }
    }, [closeDoubtStatus, teacherDoubts, active]);

    const _openDoubt = (a) => {
        setActive(a);
        onOpen();

        // history.push('/d/'+a._id)
    };

    const handlePageChange = (page) => {
        if (checkRole) history.push(`/doubts/${page}/${params.staffId}`);
        else history.push("/doubts/" + page);
    };

    const handleChangeSearch = (e) => {
        changeSearchDoubt(e.target.value);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        handlePageChange(1);
    };

    const resetSearch = () => {
        changeSearchDoubt(null);
        changeFormKey((d) => d + 1);
        handlePageChange(1);
    };

    const handleTabChange = (e) => {
        // setCurrentTab(e);
        setCurrentTab(e.target.value);
    };

    const handleOpenTestDiscussion = (test) => {
        openTestDiscussion((d) => (d ? null : test));
    };

    const handleOpenCourseDiscussion = (content) => {
        openCourseDiscussion((d) => (d ? null : content));
    };
    const handleLiveChange = (chatDetails) => {
        const staff_id = user.staff._id
        const LiveClasses = chatDetails.Live;
        const Live_id = []
        LiveClasses.forEach(element => {
            Live_id.push(element._id)
        });
        const data = {
            staff_id: staff_id,
            class: Live_id
        }
        dispatch(getBatchSubjectStatusAction({ id: data.class, status: "Ended" }));
    }
    return (
         
        <Box>
            <Box className="bottomHeader" p={3} bg="#EFF3F6">
                <div className="row align-items-center ">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-xl-0 mb-lg-0 mb-md-2 mb-2">
                        <Heading fontSize="lg">Teacher Chat Room</Heading>
                    </div>
                    <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 mb-xl-0 mb-lg-0 mb-md-2 mb-2">
                        {checkRole && (
                            <Box {...webView} flex={3}>
                                <MemorizeTeachersList resetSearch={resetSearch} />
                            </Box>
                        )}
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-xl-0 mb-lg-0 mb-md-2 mb-2">
                        <Flex>
                            <Box fontSize={[14]}>Teachers: &nbsp;</Box>
                            <Box color={"brand.blue"} fontWeight={"bold"} fontSize={[16]}>
                                {allTeachers?.length || 0}
                            </Box>
                        </Flex>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 mb-xl-0 mb-lg-0 mb-md-2 mb-2 text-end">
                        {currentTab == "0" && chatDetails && chatDetails.Live.length !== 0 ? <button onClick={() => handleLiveChange(chatDetails)} className="btn btn-secondary">Live End</button> : <></>}
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-xl-0 mb-lg-0 mb-md-2 mb-2">
                        <Box w={["100%", "100%", "100%", "100%"]}>
                            <Select borderColor={["#C1C2C2"]} value={currentTab} onChange={handleTabChange} placeItems={"Select appointment status"}>
                                <option value={0}>Live</option>
                                <option value={1}>History</option>
                                <option value={2}>Upcoming</option>
                            </Select>
                        </Box>
                    </div>
                </div>
            </Box>
            {/* <div className="chatbox-block">
                <div className="row">
                    <div className="col-sm-4 lecture-left-block">
                        <div className="search-section">
                            <InputGroup>
                                <InputRightElement pointerEvents="none" children={<Search2Icon color="#4559d2" />} />
                                <Input value={searchDoubt} name="text" onChange={handleChangeSearch} placeholder="Search Lecture" />
                            </InputGroup>
                        </div>
                    <div className="lecture-list">
                        <div class="lecture-box">
                            <div className="lecture-content">
                                <h6>Lecture -1</h6>
                                <p>Mission 220+ (English)</p>
                            </div>
                            <div className="lecture-date-time">
                                <span>27/08/2024</span> <span>18:50</span>
                            </div>
                        </div>
                        <div class="lecture-box">
                            <div className="lecture-content">
                                <h6>Lecture -1</h6>
                                <p>Mission 220+ (English)</p>
                            </div>
                            <div className="lecture-date-time">
                                <span>27/08/2024</span> <span>18:50</span>
                            </div>
                        </div>
                        <div class="lecture-box">
                            <div className="lecture-content">
                                <h6>Lecture -1</h6>
                                <p>Mission 220+ (English)</p>
                            </div>
                            <div className="lecture-date-time">
                                <span>27/08/2024</span> <span>18:50</span>
                            </div>
                        </div>
                        <div class="lecture-box">
                            <div className="lecture-content">
                                <h6>Lecture -1</h6>
                                <p>Mission 220+ (English)</p>
                            </div>
                            <div className="lecture-date-time">
                                <span>27/08/2024</span> <span>18:50</span>
                            </div>
                        </div>

                    </div>
                    </div>
                    <div className="col-sm-8 lecture-chat-list">
                        <div className="student-chatbox">
                            <div className="student-img">Image</div>
                            <div className="student-message">
                                <div className="student-name">Abhishek Yadav</div>
                                <div className="chat-message">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</div>
                            </div>
                            <div className="chat-time-sec">18:10:11</div>
                        </div>
                        <div className="student-chatbox">
                            <div className="student-img">Image</div>
                            <div className="student-message">
                                <div className="student-name">Abhishek Yadav</div>
                                <div className="chat-message">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</div>
                            </div>
                            <div className="chat-time-sec">18:10:11</div>
                        </div>
                        <div className="student-chatbox">
                            <div className="student-img">Image</div>
                            <div className="student-message">
                                <div className="student-name">Abhishek Yadav</div>
                                <div className="chat-message">It is a long established layout.</div>
                            </div>
                            <div className="chat-time-sec">18:10:11</div>
                        </div>
                        <div className="student-chatbox">
                            <div className="student-img">Image</div>
                            <div className="student-message">
                                <div className="student-name">Abhishek Yadav</div>
                                <div className="chat-message">It is a long established.</div>
                            </div>
                            <div className="chat-time-sec">18:10:11</div>
                        </div>
                        <div className="student-chatbox">
                            <div className="student-img">Image</div>
                            <div className="student-message">
                                <div className="student-name">Abhishek Yadav</div>
                                <div className="chat-message">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</div>
                            </div>
                            <div className="chat-time-sec">18:10:11</div>
                        </div>
                    </div>
                </div>
            </div> */}
            <Flex align={"stretch"}>
                {/* {checkRole && (
                    <Box pr={5} {...webView} flex={3}>
                        <MemorizeTeachersList resetSearch={resetSearch} />
                    </Box>
                )} */}

                <Box flex={10}>
                    <Box>
                        {/* <HStack>
                            {checkRole ? (
                                <VStack flex={1}>
                                    <Text color={"brand.blue"} fontWeight={"bold"} fontSize={[22, 24, 27, 30]}>
                                        {allTeachers?.length || 0}
                                    </Text>
                                    <Text fontSize={[12, 15, 17, 20]}>Teachers</Text>
                                </VStack>
                            ) : null}
                        </HStack> */}
                        {checkRole && (
                            <>
                                <br />
                                <MobileTeacherList resetSearch={resetSearch} />
                            </>
                        )}
                        <Box px={[1, 1, 1, 5]} my={5} mt={0}>
                            <Text fontSize="lg">{currentTeacher?.user?.name}</Text>
                            <Text color={"brand.secondary"}>{currentTeacher?.staffDesc}</Text>
                            <br />
                            <Box
                                className=""
                                mt={0}                                
                            >
                                {/* <Tabs isManual variant="unstyled" index={currentTab} onChange={handleTabChange} overflowX="auto"> */}
                                {/* <TabList w={["90vw", "90vw", "90vw", "100%"]} overflowX="auto">
                                        <CustomTab title={"Live"} />
                                        <CustomTab title={"Course's Doubt"}/>
                                        <CustomTab title={"History"} />
                                        <CustomTab title={"Upcoming"} />
                                    </TabList> */}
                                {/* <TabPanels>
                                        <TabPanel> */}
                                {currentTab === "0" && (
                                    <HStack alignItems={"stre"} width={"100%"}>
                                        <div style={{ width: "100%", display: "flex", gap: "10px" }}>

                                            {chatDetails && chatDetails.Live && chatDetails.Live.map((chat) => <LiveChatList key={chat._id} chat={chat} />)}
                                        </div>
                                    </HStack>
                                )}
                                {/* </TabPanel>
                                        <TabPanel> */}
                                {currentTab === "1" && <HistoryList chatDetails={chatDetails} />}
                                {/* </TabPanel>
                                        <TabPanel> */}
                                {currentTab === "2" && (
                                    <HStack alignItems={"stre"} width={"100%"}>
                                        <Box flex={4}>
                                            <UpcomingList chatDetails={chatDetails} />
                                        </Box>
                                    </HStack>
                                )}
                                {/* </TabPanel>
                                    </TabPanels>
                                </Tabs> */}
                            </Box>
                        </Box>
                        {/* {isOpen ? (
              <DoubtDetails
                checkRole={checkRole}
                currentDoubt={active}
                isOpen={isOpen}
                onClose={onClose}
              />
            ) : null}
            {testDiscussion ? (
              <DoubtDetails
                checkRole={checkRole}
                test={testDiscussion}
                isOpen={testDiscussion}
                onClose={handleOpenTestDiscussion}
              />
            ) : null}
            {courseDiscussion ? (
              <DoubtDetails
                checkRole={checkRole}
                courseContent={courseDiscussion}
                isOpen={courseDiscussion}
                onClose={handleOpenCourseDiscussion}
              />
            ) : null} */}
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
};
const LiveChatList = ({ chat }) => {
    const dispatch = useDispatch();
    const chatContainerRef = useRef(null);

    const [liveChatMessages, setLiveChatMessages] = useState([]);

    const { user, getBatchSubjectLectureStatus } = useSelector((s) => ({
        user: s.user.user,
        getBatchSubjectLectureStatus: s.doubts.getBatchSubjectLectureStatus,
    }));

    useEffect(() => {
        if (chat) {
            // if (chat?.Live[0]?.chatRoomId === undefined) {
            //   return;
            // } else {
            const chatRoomId = chat?.chatRoomId;
            const chatRoomRef = firebaseClient.database().ref(chatRoomId);

            chatRoomRef.on("value", (snapshot) => {
                const chatData = snapshot.val();
                if (chatData) {
                    const chatMessages = Object.values(chatData);
                    setLiveChatMessages(chatMessages);
                    // Scroll to the bottom of the chat container
                    if (chatContainerRef.current) {
                        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                    }
                }
            });
            // Clean up the listener when the component unmounts
            return () => {
                chatRoomRef.off("value");
            };
            // }
        }
    }, [chat]);

    useEffect(() => {
        if (getBatchSubjectLectureStatus === "SUCCESS") {
            dispatch(getChatDetailsAction({ teacherId: user?._id }));
        }
    }, [getBatchSubjectLectureStatus]);

    const handleEndClass = (e) => {
        dispatch(getBatchSubjectStatusAction({ id: [chat?._id], status: "Ended" }));
    };


    return (
        <div style={{ border: "1px solid #e2e8f0", borderRadius: "15px", maxWidth: "100%", minWidth: "33.1%" }}>
            {liveChatMessages?.length > 0 && (
                <Flex mb={2} align="center" justifyContent="space-between" style={{ padding: "15px 15px 5px 15px", borderBottom: "1px solid #e2e8f0" }}>
                    <Text>{chat?.batch}</Text>
                    <Button onClick={(e) => handleEndClass(e)}>End Class</Button>
                </Flex>
            )}
            <div ref={chatContainerRef} style={{ height: "70dvh", overflow: "auto", padding: "15px 15px 5px 15px" }} id="commentBox">
                {liveChatMessages?.length > 0 && chat?.chatRoomId !== undefined ? (
                    liveChatMessages?.map((message, i) => (
                        <Flex key={i} align="center" my={2}>
                            <Box mr={2}>
                                <Avatar src={message?.user?.avatar} name={message?.user?.name} />
                            </Box>
                            <Box>
                                <Text fontWeight="bold">{message?.user?.name}</Text>
                                <Text>{message?.text}</Text>
                                <Text fontSize="sm" color="gray.500">
                                    {moment(message?._id).format("MMMM Do YYYY, h:mm:ss a")}
                                </Text>
                            </Box>
                        </Flex>
                    ))
                ) : (
                    <Text textAlign={"center"} color={"#b2b6bc"}>
                        No Data Available
                    </Text>
                )}
            </div>
        </div>
    );
};

// const HistoryList = ({ chatDetails }) => {
//     const [roomData, setRoomData] = useState();
//     const [isLoading, setIsLoading] = useState(false);
//     const [sortedChatHistoryDetails, setSortedChatHistoryDetails] = useState(chatDetails?.Ended);
//     useEffect(() => {
//         const _chatDetails = [...chatDetails.Ended];
//         if (_chatDetails) {
//             _chatDetails.sort((b, a) => {
//                 return new Date(a.scheduleDateTime) - new Date(b.scheduleDateTime);
//             });
//             setSortedChatHistoryDetails(_chatDetails);
//         }
//     }, [chatDetails?.Ended]);

//     const chatRoomChatDetails = async (chatRoomId) => {
//         if (chatRoomId === undefined) {
//             return;
//         } else {
//             try {
//                 setIsLoading(true);
//                 const snapshot = await firebaseClient.database().ref(chatRoomId).once("value");
//                 const data = snapshot.val();
//                 const dataArray = Object.values(data);
//                 setRoomData(dataArray);
//                 setIsLoading(false);
//             } catch (error) { }
//         }
//     };
//     return (
//         <Accordion allowToggle>
//             {sortedChatHistoryDetails?.length > 0 &&
//                 sortedChatHistoryDetails?.map((endedItem, i) => (
//                     <AccordionItem style={{ marginBottom: "5px" }} key={i} onClick={() => chatRoomChatDetails(endedItem?.chatRoomId)} isDisabled={!endedItem.chatRoomId}>
//                         <AccordionButton>
//                             <Box flex="1" textAlign="left">
//                                 <div className="d-flex justify-content-between align-items-center ">
//                                     <div className="col-6">
//                                         <div className="fs-6 fw-bold ">{endedItem.lectureName}</div>
//                                         <div>{endedItem.batch}</div>
//                                     </div>
//                                     <div className="col-6"> {moment(endedItem.scheduleDateTime).format("MMMM Do YYYY, h:mm:ss a")}</div>
//                                     <div></div>
//                                 </div>
//                             </Box>
//                             <AccordionIcon />
//                         </AccordionButton>
//                         <AccordionPanel pb={4}>
//                             {isLoading ? (
//                                 <div className=" d-flex align-items-center justify-content-center ">
//                                     <span className="loader"></span>
//                                 </div>
//                             ) : roomData?.length > 0 ? (
//                                 roomData?.map((message) => (
//                                     <Flex key={message?._id} align="center" my={2}>
//                                         <Box mr={2}>
//                                             <Avatar src={message?.user?.avatar} name={message?.user?.name} />
//                                         </Box>
//                                         <Box>
//                                             <Text fontWeight="bold">{message?.user?.name}</Text>
//                                             <Text>{message?.text}</Text>
//                                             <Text fontSize="sm" color="gray.500">
//                                                 {moment(message?._id).format("MMMM Do YYYY, h:mm:ss a")}
//                                             </Text>
//                                         </Box>
//                                     </Flex>
//                                 ))
//                             ) : (
//                                 <Text style={{ textAlign: "center", color: "grey", marginTop: "5px" }}> No Chats Available</Text>
//                             )}
//                         </AccordionPanel>
//                     </AccordionItem>
//                 ))}
//         </Accordion>
//     );
// };

const HistoryList = ({ chatDetails, searchDoubt, handleChangeSearch }) => {
    const [roomData, setRoomData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sortedChatHistoryDetails, setSortedChatHistoryDetails] = useState([]);
    const [selectedChatRoom, setSelectedChatRoom] = useState(null);

    useEffect(() => {
        const _chatDetails = [...chatDetails.Ended];
        if (_chatDetails) {
            _chatDetails.sort((b, a) => new Date(a.scheduleDateTime) - new Date(b.scheduleDateTime));
            setSortedChatHistoryDetails(_chatDetails);
        }
    }, [chatDetails?.Ended]);

    const chatRoomChatDetails = async (chatRoomId) => {
        if (chatRoomId === undefined) {
            return;
        } else {
            try {
                setIsLoading(true);
                const snapshot = await firebaseClient.database().ref(chatRoomId).once("value");
                const data = snapshot.val();
                const dataArray = Object.values(data);
                setRoomData(dataArray);
                setIsLoading(false);
                setSelectedChatRoom(chatRoomId);
            } catch (error) {
                console.error("Error fetching chat details:", error);
                setIsLoading(false);
            }
        }
    };

  

    return (
        <div className="chatbox-block">
            <div className="row">
                {/* Sidebar for lectures */}
                <div className="col-sm-4 lecture-left-block">
                    <div className="seactch-dater-filter">
                    <div className="search-section">
                        <InputGroup>
                            <InputRightElement pointerEvents="none" children={<Search2Icon color="#4559d2" />} />
                            <Input value={searchDoubt} name="text" onChange={handleChangeSearch} placeholder="Search Lecture" />
                        </InputGroup>
                    </div>
                    <div className="date-section">
                        <InputGroup>
                            <Input class="form-control form-control-solid" placeholder="Pick date rage" id="kt_daterangepicker_4" />
                        </InputGroup>
                    </div>
                    </div>
                    <div className="lecture-list">
                        {sortedChatHistoryDetails?.length > 0 &&
                            sortedChatHistoryDetails.map((endedItem, i) => (
                                <div
                                    className="lecture-box"
                                    key={i}
                                    onClick={() => chatRoomChatDetails(endedItem?.chatRoomId)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div className="lecture-content">
                                        <h6>{endedItem.lectureName}</h6>
                                        <p>{endedItem.batch}</p>
                                    </div>
                                    <div className="lecture-date-time">
                                        <span>{moment(endedItem.scheduleDateTime).format('DD/MM/YYYY')}</span>
                                        <span>{moment(endedItem.scheduleDateTime).format('HH:mm')}</span>
                                    </div>
                                </div>
                           ) )}
                    </div>
                </div>

                {/* Main chat area */}
                <div className="col-sm-8 lecture-chat-list">
                    {selectedChatRoom ? (
                        <>
                            {isLoading ? (
                                <div className="d-flex align-items-center justify-content-center">
                                    <span className="loader"></span>
                                </div>
                            ) : roomData?.length > 0 ? (
                                roomData.map((message) => (
                                    <Flex key={message._id} align="center" my={2} className="student-chatbox">
                                        <Box mr={2} className="student-img">
                                            <Avatar src={message.user?.avatar} name={message.user?.name} />
                                        </Box>
                                        <Box className="student-message">
                                            <Text className="student-name" fontWeight="bold">{message.user?.name}</Text>
                                            <Text className="chat-message">{message.text}</Text>
                                        </Box>
                                        <Box className="chat-time-sec">
                                        <Text fontSize="sm">
                                                {moment(message._id).format('h:mm:ss a')}
                                            </Text>
                                        </Box>
                                    </Flex>
                                ))
                            ) : (
                                <Text style={{ textAlign: 'center', color: 'grey', marginTop: '5px' }}>
                                    No Chats Available
                                </Text>
                            )}
                        </>
                    ) : (
                        <Text style={{ textAlign: 'center', color: 'grey', marginTop: '5px' }}>
                            Select a lecture to see chats
                        </Text>
                    )}
                </div>
            </div>
        </div>
    );
};

const UpcomingList = ({ chatDetails }) => {
    const dispatch = useDispatch();
    const [selectedClasses, setSelectedClasses] = useState([]);

    // const { user } = useSelector((s) => ({
    //     user: s.user.user,
    // }));

    const handleBeginClass = (classId) => {
        let Classes = selectedClasses;
        if (Classes.includes(classId)) {
            console.log("working : ", classId)
            setSelectedClasses(Classes.filter(id => id !== classId));
        }

        dispatch(getBatchSubjectStatusAction({ id: [classId], status: "Live" }));
    };
    const _beginSelectedClasses = (classes) => {
        if (classes.length > 0) {
            console.log()
            dispatch(getBatchSubjectStatusAction({ id: classes, status: "Live" }));
        }
    }

    const _selectClass = (classId, e) => {
        let Classes = selectedClasses;
        if (Classes.includes(classId)) {
            setSelectedClasses(Classes.filter(id => id !== classId));
        }
        else {
            setSelectedClasses((classes) => [...classes, classId]);
        }

    }


    return (
        <div>

            {selectedClasses?.length > 0 ?

                <div className="d-flex justify-content-end pb-4 ">
                    <Button onClick={() => { _beginSelectedClasses(selectedClasses) }}>Begin Selected Classes</Button>
                </div>

                : <></>
            }
            {chatDetails?.Upcoming?.length > 0 &&
                chatDetails?.Upcoming?.map((message, i) => (
                    <>
                        <Box key={i} style={{ border: "1px solid #8484843d", padding: "5px", borderRadius: "5px", marginBottom: "5px" }}>
                            {/* <Flex style={{ justifyContent: "space-between", boxShadow: " #11111a0d" }}> */}
                            {/* <Box style={{ padding: "10px" }}> */}


                            <div className="d-flex align-items-center">
                                <input id={`beginClass-${i + 1}`} type="checkbox" name="begin-class-checkbox" onChange={(e) => { _selectClass(message?._id, e) }} style={{ width: "18px", height: "18px", display: "inline-block", marginRight: "12px", marginLeft: "5px", cursor: "pointer " }} />
                                <div className="d-flex align-items-center justify-content-between " style={{ width: "calc(100% - 35px)" }}>
                                    <div className="col-4">
                                        <div className="fs-6 fw-bold ">{message?.lectureName}</div>
                                        <div>{message?.batch}</div>
                                    </div>
                                    <div className="col-4 text-center">
                                        <div style={{ padding: "10px" }}>{moment(message?.scheduleDateTime).format("MMMM Do YYYY, h:mm:ss a")}</div>
                                    </div>
                                    <div className="col-4 text-end">
                                        <Button onClick={() => handleBeginClass(message?._id)}>Begin Class</Button>
                                    </div>
                                </div>
                            </div>


                            {/* </Box> */}

                            {/* </Flex> */}
                        </Box>
                    </>
                ))}
        </div>
    );
};

const CustomTab = ({ title }) => {
    return (
        <Tab marginX={[1, 1, 1, 2]} paddingX={3} p={2} background="#EFF3F6" color={"black"} _selected={{ background: "brand.blue", color: "white", borderRadius: 4 }}>
            {title}
        </Tab>
    );
};

const MobileTeacherList = ({ resetSearch }) => {
    const params = useParams();
    const { teachers } = useSelector((state) => ({
        teachers: state.doubts.allTeachers,
    }));

    const [currentTeacher, setTeacher] = useState();
    const [openIndex, changeOpenIndex] = useState([0]);

    useEffect(() => {
        changeOpenIndex([]);
    }, [params.staffId]);

    useEffect(() => {
        if (params.staffId && teachers) {
            let staff = _.find(teachers, (t) => t.user?._id === params.staffId);
            setTeacher(staff);
        }
    }, [params, teachers]);

    const handleOpen = () => {
        changeOpenIndex((d) => (d.length ? [] : [0]));
    };

    return (
        <>
            <Accordion index={openIndex} allowMultiple {...mobileView}>
                <AccordionItem>
                    <AccordionButton onClick={handleOpen}>
                        <Box flex="1" textAlign="left">
                            <HStack justify={"space-between"}>
                                <Text color={"brand.blue"}>{currentTeacher?.user.name}</Text>
                                <Text>Teachers ({teachers?.length}) &nbsp;</Text>
                            </HStack>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <MemorizeTeachersList resetSearch={resetSearch} hideHeading />
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <Flex justifyContent="flex-end" display={["flex", "flex", "flex", "none"]} my={2}>
                {/* {
        currentTeacher &&
          <Box>
            {
              currentTeacher?.doubtCountResolved && <Tag colorScheme="red" mr={2}>Closed ({currentTeacher.doubtCountResolved})</Tag>
            }
            <Tag colorScheme="blue">Total ({currentTeacher?.doubtCount || 0})</Tag>
          </Box>
      } */}
            </Flex>
        </>
    );
};

const TeachersList = ({ hideHeading, resetSearch }) => {
    const history = useHistory();
    const params = useParams();

    const [allTeachers, changeTeachers] = useState([]);
    const [sortOn, setSortOn] = useState("doubt");
    const [sortType, changeSortType] = useState("asc");
    const { teachers } = useSelector((state) => ({
        teachers: state.doubts.allTeachers,
    }));

    useEffect(() => {
        if (teachers?.length) changeTeachers(teachers);
    }, [teachers]);

    useEffect(() => {
        handleSort();
    }, [sortOn, sortType]);
    // const [currentTeach, setCurrentTeach] = useState()

    const handleTeacher = (teach) => {
        // setCurrentTeach(teach)
        resetSearch();
        history.push(`/doubts/1/${teach.user?._id}`);
    };

    const handleChange = (e) => {
        let searchText = e.target.value;
        let list = _.filter(teachers, (t) => _.includes(_.lowerCase(t?.user?.name), _.lowerCase(searchText)));
        changeTeachers(list);
    };
    const handleSort = () => {
        if (sortOn === "doubt") changeTeachers((d) => _.orderBy(d, (doubt) => (!doubt.doubtCount ? 0 : doubt.doubtCount), sortType));
        else changeTeachers((d) => _.orderBy(d, (doubt) => (!doubt.doubtCountResolved || !doubt.doubtCount ? 0 : doubt.doubtCountResolved / doubt.doubtCount), sortType));
    };
    return (
        <List h="100%">
            {hideHeading ? null : (
                <ListItem background={"gray.100"} borderRadius={4} py={2} px={3}>
                    <HStack>
                        <Text>Teachers</Text>
                        <Text>({allTeachers?.length || 0})</Text>
                    </HStack>
                </ListItem>
            )}
            <ListItem my={3}>
                <HStack>
                    <Box flex={1}>
                        <InputGroup>
                            <InputLeftElement pointerEvents="none" children={<Search2Icon color="gray.300" />} />
                            <Input onChange={handleChange} placeholder="Search Teacher" />
                        </InputGroup>
                    </Box>
                </HStack>
            </ListItem>
            <ListItem my={3}>
                <Flex>
                    <Button
                        onClick={() => changeSortType((d) => (d === "asc" ? "desc" : "asc"))}
                        size="sm"
                        variant="outline"
                        colorScheme="blue"
                        width={"100%"}
                        mr={3}
                        rightIcon={sortType === "asc" ? <ArrowDownIcon /> : <ArrowUpIcon />}
                    >
                        <Box>Sort </Box>
                    </Button>
                    <Menu>
                        <MenuButton as={Button} size="sm" px={4} colorScheme="blue" rightIcon={<ChevronDownIcon />}>
                            {sortOn === "doubt" ? "Count" : "Top"}
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => setSortOn("doubt")}>Doubt count</MenuItem>
                            <MenuItem onClick={() => setSortOn("top comment")}>Top</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </ListItem>
            <ListItem my={3}>
                <HStack>
                    <Box flex={1}></Box>
                </HStack>
            </ListItem>
            <List
                maxH="100vh"
                overflowY="scroll"
                overflowX="hidden"
                pos="sticky"
                top="0px"
                css={{ "&::-webkit-scrollbar": { width: "6px" }, "&::-webkit-scrollbar-thumb": { background: "#cdcdcd", borderRadius: "5px" } }}
            >
                {allTeachers?.length
                    ? allTeachers.map((teach) => {
                        const active = teach.user?._id === params.staffId;
                        return (
                            teach.user && (
                                <ListItem
                                    borderRadius={active ? 5 : 2}
                                    my={1}
                                    cursor={"pointer"}
                                    _hover={{ background: "blue.200", color: "white" }}
                                    py={1}
                                    px={2}
                                    key={teach._id}
                                    transition="all .3s"
                                    onClick={() => handleTeacher(teach)}
                                    background={active && "brand.blue"}
                                    color={active ? "white" : "brand.secondary"}
                                >
                                    <HStack justify={"space-between"} alignItems="flex-start">
                                        <Box pt={1}>
                                            <Text>{teach.user?.name}</Text>
                                            <Text fontSize="xs">{teach.staffDesc}</Text>
                                        </Box>
                                        <HStack display={"flex"}>
                                            {/* <Text fontSize={12}>Doubts: {teach.doubtCount || 0}</Text> */}
                                            <Box fontSize={11}>
                                                <Box>Doubts</Box>
                                                <Flex justifyContent="center">
                                                    <Box>{teach?.doubtCountResolved || 0}</Box>
                                                    <Box px={1}>/</Box>
                                                    <Box textAlign="left">{teach.doubtCount || 0}</Box>
                                                </Flex>
                                            </Box>
                                            {<ChevronRightIcon visibility={active ? "" : "hidden"} fontSize={24} color={"white"} />}
                                        </HStack>
                                    </HStack>
                                </ListItem>
                            )
                        );
                    })
                    : null}
            </List>
        </List>
    );
};

const MemorizeTeachersList = React.memo(TeachersList);

const DoubtItem = ({ onPress, d, i }) => {
    const dispatch = useDispatch();
    const [isCopied, setIsCopied] = useState(false);
    const doubtStatus = d.status === "Closed" ? { label: "Closed", color: "red" } : { label: d.status, color: "green" };

    const publicStatus = { label: "Public", color: "green" };

    const _open = () => {
        onPress(d);
        // dispatch(get)
    };
    const copyText = (text) => {
        if (!navigator || isCopied) return;
        navigator.clipboard.writeText(text);
        setIsCopied(true);
        window.setTimeout(() => {
            setIsCopied(false);
        }, 1300);
    };
    return (
        <Flex onClick={_open} p={2} px={4} _hover={{ bg: "gray.100", boxShadow: "sm" }} cursor="pointer" border="1px solid #dddddd" borderRadius={"md"} m={1}>
            <Box mr={5}>
                <Text fontSize="" color="gray.700">
                    {i}.
                </Text>
            </Box>
            <Box>
                <Flex>
                    <Text fontSize="sm" color="gray.600">
                        {d.userId?.name}
                    </Text>
                    <Text color="gray.500" fontSize="xs" ml={10}>
                        <i>{moment(d.createdAt).format("DD MMM YYYY hh:mm a")}</i>
                    </Text>
                </Flex>
                <Box pb={2} pt={1}>
                    <Text fontSize={"sm"}>{d.doubt}</Text>
                </Box>
                <HStack mt={3}>
                    <Tag size="sm" colorScheme={doubtStatus.color}>
                        {doubtStatus.label}
                    </Tag>
                    {d.public ? <Tag colorScheme={publicStatus.color}>{publicStatus.label}</Tag> : null}
                    <Box onClick={(e) => e.stopPropagation()}>
                        <Popover>
                            <PopoverTrigger>
                                <Tag _hover={{ boxShadow: "lg" }} size="sm" colorScheme="blue">
                                    <PhoneIcon color="blue.400" />
                                </Tag>
                            </PopoverTrigger>
                            <PopoverContent boxShadow="none!important">
                                <PopoverArrow />
                                {/* <PopoverCloseButton /> */}
                                {/* <PopoverHeader>Contact</PopoverHeader> */}
                                <Flex justifyContent="space-between">
                                    <PopoverBody textAlign="center" flexGrow="1">
                                        {d.userId?.contact}
                                    </PopoverBody>
                                    <Button variant="ghost" mr={2} onClick={() => copyText(d.userId?.contact)}>
                                        {isCopied ? <CheckCircleIcon color="green.400" /> : <CopyIcon />}
                                    </Button>
                                </Flex>
                            </PopoverContent>
                        </Popover>
                    </Box>
                </HStack>
            </Box>
        </Flex>
    );
};

const DoubtDetails = ({ isOpen, onClose, currentDoubt, test, courseContent, checkRole }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => dispatch(resetReadAction());
    }, [dispatch]);

    useEffect(() => {
        let itemId = test?.itemId || courseContent?.itemId;
        if (itemId && !checkRole) dispatch(readCommentAction({ itemId }));
    }, [dispatch, courseContent, test, checkRole]);

    return (
        <Drawer isOpen={isOpen} size={["xl"]} placement="right" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent pt={10}>
                <DrawerCloseButton mt={10} />
                <DrawerHeader>
                    {test ? (
                        <Box alignItems="center">
                            <Box color="gray.800">{test.itemName}</Box>
                            {/* <Box fontSize={13} color="gray.500"><i>{moment(currentDoubt.createdAt).format("DD MMM YYYY, hh mm a")}</i></Box> */}
                        </Box>
                    ) : courseContent ? (
                        <Box alignItems="center">
                            <Box color="gray.800">{courseContent.itemName}</Box>
                            {/* <Box fontSize={13} color="gray.500"><i>{moment(currentDoubt.createdAt).format("DD MMM YYYY, hh mm a")}</i></Box> */}
                        </Box>
                    ) : (
                        <Box alignItems="center">
                            <Box color="gray.800">{currentDoubt.user?.name}</Box>
                            <Box fontSize={13} color="gray.500">
                                <i>{moment(currentDoubt.createdAt).format("DD MMM YYYY, hh mm a")}</i>
                            </Box>
                        </Box>
                    )}
                    {/* <Flex alignItems="flex-end">
            <IconButton icon={<CloseIcon />} onClick={onClose} />
          </Flex> */}
                </DrawerHeader>
                {/* <DrawerHeader>Create your account</DrawerHeader> */}
                <DrawerBody style={{ maxHeight: "100vh" }}>
                    {test ? (
                        <Box>
                            {/* <HStack>
                <AiOutlineFileProtect color={'#3C4043B2'} fontSize={20}/>
                <Text color={'brand.secondary'} fontSize={18} fontWeight='bold'>
                  {test.itemModel === 'Test' ?  bilingualText(test.itemId.name) : test.itemId.title}
                </Text>
              </HStack>
              <br/> */}
                            <DiscussionComments
                                onBottomAtSmall={true}
                                // editCommentUserId={ checkRole && user._id }
                                commentsLabel="Comments"
                                showAttachment={false}
                                placeholder="Add a comment"
                                inputStyle="flushed"
                                itemId={test.itemId}
                                includeDoubt={2}
                            />
                        </Box>
                    ) : courseContent?.content ? (
                        <Box>
                            {/* <HStack>
                  {courseContent.itemModel === 'Audio' ?  <AiOutlineAudio fontSize={20}/> : <AiOutlineVideoCamera fontSize={20}/>}
                  <Text color={'brand.secondary'} fontSize={18}>
                    {courseContent.content?.itemName}
                  </Text>
                </HStack>
                <br/> */}
                            <DiscussionComments
                                onBottomAtSmall={true}
                                // editCommentUserId={ checkRole && user._id }
                                commentsLabel="Comments"
                                showAttachment={false}
                                placeholder="Add a comment"
                                inputStyle="flushed"
                                itemId={courseContent.itemId}
                                itemModel={courseContent.itemModel}
                                contentId={courseContent.content}
                                courseId={courseContent.course}
                                includeDoubt={2}
                            />
                        </Box>
                    ) : (
                        <DoubtComponent currentDoubt={currentDoubt} />
                    )}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export const DoubtComponent = ({ currentDoubt }) => {
    const dispatch = useDispatch();

    const { closeDoubtStatus, publicDoubtStatus, user } = useSelector((state) => ({
        closeDoubtStatus: state.doubts.closeDoubtStatus,
        publicDoubtStatus: state.doubts.publicDoubtStatus,
        user: state.user.user,
    }));

    const checkRole = useMemo(() => {
        return user?.staff?.staffRole === "ADMIN" || user?.staff?.staffRole === "HEAD_TEACHER" || user?.staff?.staffRole === "TEACHER";
    }, [user?.staff?.staffRole]);

    const handleClose = () => {
        let data = {
            status: "Closed",
            doubtId: currentDoubt._id,
            closedDate: moment().format(),
        };
        dispatch(closeDoubtAction(data));
    };

    const handlePublic = () => {
        let data = {
            doubtId: currentDoubt._id,
            public: currentDoubt.public ? false : true,
        };
        dispatch(publicDoubtAction(data));
    };

    return (
        <>
            <HStack>
                <Button disabled={currentDoubt.status === "Closed"} size="sm" isLoading={closeDoubtStatus === STATUS.FETCHING} onClick={handleClose} variant="outline" color={"brand.red"}>
                    {currentDoubt.status === "Closed" ? "Closed" : "Close Doubt"}
                </Button>
                <Button
                    size="sm"
                    isLoading={publicDoubtStatus === STATUS.FETCHING}
                    variant="outline"
                    onClick={handlePublic}
                    color={currentDoubt.public ? "brand.red" : "brand.green"}
                    disabled={currentDoubt.status === "Open"}
                >
                    {currentDoubt.public ? "Make it Private" : "Make it Public"}
                </Button>
            </HStack>
            <br />
            <Box
                fontSize="md"
                color={"brand.secondary"}
                fontFamily='"Lato", sans-serif'
                as="pre"
                py={3}
                sx={{
                    "white-space": "-moz-pre-wrap",
                    "white-space": "-o-pre-wrap",
                    "word-wrap": "break-word",
                    "white-space": "pre-wrap",
                }}
            >
                {currentDoubt.doubt}
            </Box>
            <br />
            <br />
            <Box>
                <DiscussionComments
                    onBottomAtSmall={true}
                    editCommentUserId={checkRole && user._id}
                    commentsLabel="Comments"
                    showAttachment={true}
                    placeholder="Add a comment"
                    inputStyle="flushed"
                    itemId={currentDoubt._id}
                />
            </Box>
        </>
    );
};
