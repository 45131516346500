import React, { useReducer, useState, useEffect, useCallback } from "react";
import { STATUS } from "../../App/Constants";
import {
    Box,
    Image,
    Flex,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Button,
    Spacer,
    Text,
    InputGroup,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    IconButton,
    useColorMode,
    useDisclosure,
    Badge,
    useToast,
    HStack,
    Select,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Divider,
    InputLeftElement,
} from "@chakra-ui/react";
import { useAppContext, useIsAuthenticated } from "../../App/Context";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Search2Icon, PhoneIcon, HamburgerIcon, SearchIcon } from "@chakra-ui/icons";
import { IoPersonCircleOutline } from "react-icons/io5";
import { useWindowSize } from "../../utils/useWindowSize";
import { LoginRoute } from "../../routes/Login";
import $ from "jquery";
import CartContext from "../../Cart/useCart";
import { AiOutlineLogin, AiOutlineLogout, AiOutlineBell } from "react-icons/ai";
import { CUSTOM_ROUTES, ROUTES } from "../../Constants/Routes";
import { BasicDetailsComponent } from "../../routes/BasicDetailsComponent";
import { Dropdown, ProfileDropdown } from "../ProfileDropdown";
import { useLoginModal } from "../../App/useLoginModal";
import { CONTACT_IT_HELP, CONTACT_OTHER } from "../../Constants/Config";
import { BiMenu } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { SideBarContent } from "../Sidebar";
import { mobileView, webView } from "../../utils/Helper";

export const CommonHeader = ({ pageContext, config }) => {
    const context = React.useContext(CartContext);
    const isAuthenticated = useIsAuthenticated();
    const [width, height] = useWindowSize();
    const { logout } = useAppContext();
    const history = useHistory();
    const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

    const { user } = useSelector((s) => ({
        user: s.user,
    }));

    const { colorMode, toggleColorMode } = useColorMode();

    const { modalType, setModalType, closeLoginModal, openLoginModal, toggleLoginModal } = useLoginModal();
    // const [modalType , setModalType] = useState(SIGNIN_MODAL_TYPE.SIGNIN)

    const _signInOut = () => {
        if (isAuthenticated) {
            logout();
        } else {
            setModalType(SIGNIN_MODAL_TYPE.SIGNIN);
            toggleLoginModal();
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            if (modalType !== SIGNIN_MODAL_TYPE.COUPON_LOGIN) {
                closeLoginModal();
            }
        }
    }, [modalType, isAuthenticated, closeLoginModal]);

    return (
        <Box style={{ position: "sticky", top: "0",zIndex:"2" }}>
            <Box {...mobileView}>
                <Box>
                    <Box backgroundImage="url(/images/Header_1920.jpg)">
                        <HStack justifyContent="space-between">
                            <Box w="33%">
                                <MobileDrawer />
                            </Box>
                            <HStack py={3} w="33%" justifyContent="center">
                                <Image
                                    w="100px"
                                    src={"/logo-white.png"}
                                    alt="Rankplus"
                                    cursor="pointer"
                                    // onClick={() => history.push("/")}
                                />
                            </HStack>
                            <HStack justifyContent="flex-end" w="33%">
                                {isAuthenticated ? (
                                    <HStack>
                                        <Box>
                                            <Box pos="relative" cursor="pointer" onClick={() => history.push("/notifications")}>
                                                {context?.cart?.packages?.length ? (
                                                    <Badge position="absolute" top={-2} right={-1} zIndex={9} background="#E74C3C" variant="solid" fontSize="10px">
                                                        {context.cart.packages.length}
                                                    </Badge>
                                                ) : null}
                                                <AiOutlineBell fontSize="20px" color="#95A2E5" />
                                            </Box>
                                        </Box>

                                        <IconButton onClick={_signInOut} variant="ghost" fontSize="20px" color="#95A2E5" icon={<AiOutlineLogout />} />
                                    </HStack>
                                ) : (
                                    <IconButton fontSize="20px" color="#95A2E5" onClick={_signInOut} variant="ghost" icon={<AiOutlineLogin />} />
                                )}
                            </HStack>
                        </HStack>
                    </Box>
                </Box>
            </Box>
            <Box {...webView} color="white">
                <Box
                    pos="relative"
                    backgroundColor="#4559D2"
                    backgroundImage="url(/images/Header_1920.jpg)"
                    backgroundRepeat="no-repeat"
                    // backgroundSize='100%'
                    h={"3.51vw"}
                >
                    <HStack pos="absolute" px={3} right={0} left={0} top={0} bottom={0} alignItems="center" wrap>
                        <HStack w={"25%"} style={{ verticalAlign: "middle" }} align="center" py={2} spacing={4}>
                            <Image
                                w="6.80vw"
                                src={"/logo-white.png"}
                                alt="Rankplus logo"
                                cursor="pointer"
                                // onClick={() => history.push("/")}
                            />
                            {/* <Text>Rankplus Live</Text> */}
                        </HStack>
                        <Spacer />
                        <Box />
                        <Spacer />
                        <Box>
                            <SideBarContent top />
                        </Box>
                        <HStack align="center">
                            <HStack wrap="wrap" spacing={6}>
                                {/*<Button size="sm" onClick={toggleColorMode}>
                    Toggle {colorMode === "light" ? "Dark" : "Light"}
                  </Button>*/}
                                {/* <HStack fontSize="sm">
                  <PhoneIcon color="#13F0FA" />
                  <Text color="#13F0FA">IT Help : {CONTACT_IT_HELP}</Text>{" "}
                </HStack>
                <HStack fontSize="sm">
                  <PhoneIcon />
                  <Text color="white">
                    Other Enquiry : {CONTACT_OTHER}
                  </Text>{" "}
                </HStack> */}
                                {user.user ? (
                                    <HStack spacing={3}>
                                        <Box>
                                            <Box pos="relative" onClick={() => history.push("/notifications")} cursor="pointer">
                                                <AiOutlineBell fontSize="1.52vw" color="#fff" />
                                            </Box>
                                        </Box>
                                    </HStack>
                                ) : null}
                            </HStack>
                        </HStack>
                        <Box display="flex" alignItems="center">
                            {isAuthenticated ? (
                                <span
                                    width="300px"
                                    style={{
                                        display: "inline-block",
                                        color: "#fff",
                                        fontSize: "",
                                        padding: "",
                                    }}
                                >
                                    <ProfileDropdown />
                                </span>
                            ) : (
                                <span
                                    style={{
                                        display: "inline-block",
                                        color: "#717678",
                                        fontSize: "",
                                        padding: "",
                                        cursor: "pointer",
                                    }}
                                    onClick={_signInOut}
                                >
                                    <Button
                                        variant="ghost"
                                        colorScheme="white"
                                        fontWeight="normal"
                                        leftIcon={<IoPersonCircleOutline style={{ fontSize: "22px" }} />}
                                        _focus={{ boxShadow: "none" }}
                                        fontSize="sm"
                                        style={{ color: "#fff" }}
                                    >
                                        {isAuthenticated ? "Sign Out" : "Sign In"}
                                    </Button>
                                </span>
                            )}
                        </Box>
                    </HStack>
                </Box>
                {/* <Flex justify={"space-between"} alignItems="center" bg="#EFF3F6" p="2px">
                    <SideBarContent top />
                    {width < 900 ? (
                        <Menu>
                            <MenuList
                                style={{
                                    backgroundColor: "#2c7de9",
                                    border: "0px",
                                    padding: "10px",
                                }}
                            >
                                <MenuComponent isAuthenticated={isAuthenticated} hamburger={true} />
                            </MenuList>
                        </Menu>
                    ) : null}
                    {width > 900 ? (
                        <Box style={{ paddingLeft: "1rem" }}>
                            <MenuComponent isAuthenticated={isAuthenticated} hamburger={false} signInOut={_signInOut} />
                        </Box>
                    ) : null}
                    <Spacer />
                    <Box display="flex" alignItems="center">
                        {isAuthenticated ? (
                            <span
                                width="300px"
                                style={{
                                    display: "inline-block",
                                    color: "#717678",
                                    fontSize: "",
                                    padding: "",
                                }}
                            >
                                <ProfileDropdown />
                            </span>
                        ) : (
                            <span
                                style={{
                                    display: "inline-block",
                                    color: "#717678",
                                    fontSize: "",
                                    padding: "",
                                    cursor: "pointer",
                                }}
                                onClick={_signInOut}
                            >
                                <Button
                                    variant="ghost"
                                    colorScheme="white"
                                    fontWeight="normal"
                                    leftIcon={<IoPersonCircleOutline style={{ fontSize: "22px" }} />}
                                    _focus={{ boxShadow: "none" }}
                                    fontSize="sm"
                                    style={{ color: "#717678" }}
                                >
                                    {isAuthenticated ? "Sign Out" : "Sign In"}
                                </Button>
                            </span>
                        )}
                    </Box>
                </Flex> */}
                <SignInFlow />
                {/* <CompleteProfilePopUp /> */}
                {isOpen ? <Dropdown isOpen={isOpen} user={user} close={onClose} /> : null}
            </Box>
        </Box>
    );
};

function MobileDrawer() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const history = useHistory();
    //console.log(tests);

    const isAuthenticated = useIsAuthenticated();

    const { logout } = useAppContext();
    const { setModalType, toggleLoginModal } = useLoginModal();

    const _signInOut = () => {
        if (isAuthenticated) {
            logout();
        } else {
            setModalType(SIGNIN_MODAL_TYPE.SIGNIN);
            toggleLoginModal();
        }
        onClose();
    };

    return (
        <>
            <IconButton colorScheme="url(/images/Header_1920.jpg)" icon={<HamburgerIcon />} onClick={onOpen} />
            <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader fontWeight="bold" color="gray.800" bgColor="gray.50">
                        Competition Community
                    </DrawerHeader>
                    <Divider />
                    <DrawerBody>
                        {!isAuthenticated ? (
                            <Box py={2} onClick={_signInOut}>
                                {isAuthenticated ? "Sign Out" : "Sign In"}
                            </Box>
                        ) : null}
                        {isAuthenticated ? <SideBarContent /> : null}
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
}

const MenuComponent = ({ isAuthenticated, signInOut, hamburger }) => {
    const history = useHistory();
    const toast = useToast();
    //console.log("hamburger",hamburger)
    //hamburger = true
    const handleSignIn = () => {
        toast({
            title: "Sign In to Continue.",
            position: "top",
            variant: "subtle",
            status: "info",
            duration: 2500,
            isClosable: true,
        });

        signInOut();
    };

    const location = useLocation();
    const [openMenu, changeOpenMenu] = useState();
    const _openMenu = () => {
        //console.log("In OpenMenu")
        changeOpenMenu(!openMenu);
        // if (openMenu) $("#sidebar").animate({ width: 0 });
        // else $("#sidebar").animate({ left: "250px", width: "20%" });
    };

    // useEffect(() => {
    //   // changeOpenMenu(false);
    //   $("#sidebar").animate({ width: 0 });
    // }, []);

    return (
        <HStack spacing={7}>
            {/* {isAuthenticated ? (
        <Menu>
          <MenuButton
            className="menuButton"
            fontSize="xs"
            // onClick={openMenu}
            onClick={_openMenu}
          >
            <BiMenu fontSize="2.2vw" color="#4285F4" />
          </MenuButton>
        </Menu>
      ) : null} */}
            {/* <Menu>
        <MenuButton
          fontSize="xs"
          style={{ color: "#717678" }}
          _hover={menuButtonHoverStyle}
          onClick={() => history.push("/")}
        >
          HOME
        </MenuButton>
      </Menu> */}
            {hamburger ? <hr style={{ paddingTop: "5px", paddingBottom: "3px" }} /> : null}
        </HStack>
    );
};

export const SIGNIN_MODAL_TYPE = {
    SIGNIN: "signin",
    COUPON_LOGIN: "coupon",
};

const SignInFlow = () => {
    const { modalType, isLoginModalOpen, toggleLoginModal } = useLoginModal();

    return (
        <Modal size="xl" isOpen={isLoginModalOpen} onClose={toggleLoginModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Sign In</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <LoginRoute />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const CompleteProfilePopUp = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isConfirmed, setIsConfirmed] = useState(null);

    const { user } = useSelector((s) => ({
        user: s.user.user,
    }));

    useEffect(() => {
        if (user && !user.confirmed) {
            setIsConfirmed(false);
            onOpen();
        } else {
            setIsConfirmed(true);
            onClose();
        }
    }, [onClose, onOpen, user]);

    const toast = useToast();

    const handleModalClose = () => {
        if (isConfirmed) {
            onClose();
        } else {
            toast.closeAll();
            toast({
                title: "Please Complete your Profile first.",
                position: "top",
                variant: "subtle",
                status: "warning",
                duration: 2500,
                isClosable: false,
            });
        }
    };

    return (
        <Modal size="xl" isOpen={isOpen} onClose={handleModalClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Complete Your Profile</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <BasicDetailsComponent />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const SearchBarHeader = ({ width }) => {
    const history = useHistory();
    const [searchData, changeSearchData] = useState("");
    const [searchType, changeSearchType] = useState("all");

    const search = (e) => {
        e.preventDefault();
        history.push(ROUTES.PACKAGES + `?search=${searchData}&searchType=${searchType}`);
    };

    // const [isSmallDevice] = useMediaQuery("(max-width: 48em)");

    // if (isSmallDevice) {
    //   return (
    //     <Box key="small" p={2} bg="gray.50" width={width} display={"inline"}>
    //       <form onSubmit={search} width={width}>
    //         <Box>
    //           <InputGroup>
    //             <InputLeftElement
    //               pointerEvents="none"
    //               children={<SearchIcon color="gray.300" />}
    //             />
    //             <Input
    //               background="white"
    //               color="black"
    //               borderRadius="full"
    //               placeholder="What do you want to learn"
    //               onChange={(e) => changeSearchData(e.target.value)}
    //               // borderRightRadius={2}
    //             />
    //           </InputGroup>
    //         </Box>
    //       </form>
    //     </Box>
    //   );
    // }
    return (
        <Box w={width} p={[3, 3, 3, 0]} bg={["gray.50", "gray.50", "transparent"]}>
            <form onSubmit={search}>
                {/* <InputLeftAddon style={{paddingRight: '0px'}}>
                    <Menu style={{backgroundColor: 'lightGrayBlue', color: "text.100", border: '0px'}}>
                        <MenuButton style={{color: "text.100"}}>Exam &nbsp;&nbsp;<ChevronDownIcon/>&nbsp;&nbsp;</MenuButton>
                        <Portal>
                            <MenuList>
                            <MenuItem>Exam</MenuItem>                           
                            </MenuList>
                        </Portal>
                    </Menu>
                </InputLeftAddon> */}
                <InputGroup>
                    <InputLeftElement {...mobileView} pointerEvents="none" my={2} children={<SearchIcon color="gray.300" />} />
                    <Select
                        {...webView}
                        size="sm"
                        defaultValue={"exam"}
                        color="black"
                        w="30%"
                        borderRightRadius={0}
                        borderLeftRadius={3}
                        background="white"
                        onChange={(e) => changeSearchType(e.target.value)}
                    >
                        <option value="all" style={{ padding: 4 }}>
                            All
                        </option>
                        <option value="COURSE" style={{ padding: 4 }}>
                            Courses
                        </option>
                        <option value="TEST" style={{ padding: 4 }}>
                            Tests
                        </option>
                        <option value="BOOK" style={{ padding: 4 }}>
                            Books
                        </option>
                        <option value="MAGAZINE" style={{ padding: 4 }}>
                            Magazines
                        </option>

                        {/* <option value='package'>Package</option> */}
                    </Select>

                    <Input
                        size={["lg", "lg", "lg", "sm"]}
                        background="white"
                        color="black"
                        py={[2, 2, 2, 0]}
                        borderRadius={["full", "full", "full", 0]}
                        placeholder="What do you want to learn"
                        variant={["outline", "outine", "filled"]}
                        onChange={(e) => changeSearchData(e.target.value)}
                        // borderRightRadius={2}
                    />
                    <IconButton
                        size="sm"
                        width="10%"
                        {...webView}
                        type="submit"
                        // disabled={!searchData}
                        icon={<Search2Icon />}
                        // borderRadius={0}
                        colorScheme="yellow"
                        borderRightRadius={3}
                        borderLeftRadius={0}
                        color="white"
                    />
                </InputGroup>
            </form>
            <Box {...mobileView} p={3} mt={2} textAlign="center">
                {/* <HStack fontSize="xs">
          <PhoneIcon color="#13F0FA" />
          <Text color="gray.500">IT Help : {CONTACT_IT_HELP}</Text>{" "}
          <Text color="gray.500">Other : {CONTACT_OTHER}</Text>{" "}
        </HStack>{" "} */}
            </Box>
        </Box>
    );
};
