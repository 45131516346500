import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, FormLabel, Select, useToast } from '@chakra-ui/react';
import { getOtherUserScheduleAction } from '../../redux/reducers/planner';

import EditModal from './EditModal';
import CustomAlertDialog from './CustomAlertDialog';

const defaultDataObj = {
    teacher: {
        label: "",
        value: ""
    },
    class: {
        label: "",
        value: ""
    }
};

const SwapModule = ({ show, toggleModal, activeStaffsList, handleEventSwap, filterEventId }) => {
    const [data, setData] = useState({ ...defaultDataObj });
    const [showDialog, setShowDialog] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState('');
    const [selectedStaffEvents, setSelectedStaffEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState('');
    const [isClassDropdownDisabled, setIsClassDropdownDisabled] = useState(true);

    const toast = useToast();
    const dispatch = useDispatch();
    const { otherPlannerScheduleDetails } = useSelector((s) => ({
        otherPlannerScheduleDetails: s.planner.otherPlannerScheduleDetails,
    }));


    useEffect(() => {
        if (selectedStaff) {
            dispatch(getOtherUserScheduleAction({ teacherId: selectedStaff }));
        }
    }, [selectedStaff, dispatch]);


    useEffect(() => {
        if (otherPlannerScheduleDetails && otherPlannerScheduleDetails?.planner?.length > 0) {
            const allEvents = otherPlannerScheduleDetails.planner.flatMap(item => item.EventsData);
            const activeEvents = allEvents.filter(event => event.eventStatus === true);
            setSelectedStaffEvents(activeEvents);
        }
    }, [otherPlannerScheduleDetails]);


    useEffect(() => {
        setSelectedEvent('');
        setIsClassDropdownDisabled(true);
    }, [selectedStaff]);


    useEffect(() => {
        if (!show) {
            setSelectedStaff('');
            setSelectedStaffEvents([]);
            setSelectedEvent('');
            setIsClassDropdownDisabled(true);
        }
    }, [show]);


    useEffect(() => {
        setIsClassDropdownDisabled(selectedStaff === '' || selectedStaffEvents.length === 0);
    }, [selectedStaff, selectedStaffEvents]);

    const openDialog = (e) => {
        e.preventDefault();
        if (selectedStaffEvents.length === 0) {
            toast({
                title: 'Please select staff with assigned events!',
                status: 'warning',
                isClosable: true,
                position: 'top',
            });
            return;
        }
        if (selectedEvent === '') {
            toast({
                title: 'Please select a class!',
                status: 'warning',
                isClosable: true,
                position: 'top',
            });
            return;
        }
        setShowDialog(true);
    };

    const handleStaffChange = (e) => {
        const { value } = e.target;
        setSelectedStaff(value);
    };

    const handleEventChange = (e) => {
        const { value } = e.target;
        setSelectedEvent(value);
    };

    const handleCloseEditModal = () => {
        toggleModal();
    };

    const handleSubmit = () => {
        const userId = activeStaffsList?.find(staff => staff.user._id === selectedStaff);
        if (userId) {
            handleEventSwap({ staffId: userId._id, eventId: selectedEvent });
        }
    };


    const now = moment();


    const filteredEvents = selectedStaffEvents?.filter(event => moment(event?.startDate).isAfter(now) && event?._id !== filterEventId);

    return (
        <>
            <EditModal
                isOpen={show}
                onClose={handleCloseEditModal}
                title="Swap Class"
                onSubmit={openDialog}
                size="xl"
            >
                <FormControl>
                    <FormLabel>Select Teacher</FormLabel>
                    <Select
                        placeholder="Select option"
                        onChange={handleStaffChange}
                        value={selectedStaff}
                    >
                        {activeStaffsList?.map((staff) => (
                            <option value={staff.user._id} key={staff.user._id}>
                                {staff.user.name}
                            </option>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>Select Class</FormLabel>
                    <Select
                        onChange={handleEventChange}
                        value={selectedEvent}
                        placeholder="Select option"
                        disabled={isClassDropdownDisabled}
                    >
                        {filteredEvents?.map((event) => (
                            <option value={event._id} key={event._id}>
                                {event.topic}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            </EditModal>
            <CustomAlertDialog
                title="Swap Class"
                isOpen={showDialog}
                onClose={() => setShowDialog(false)}
                onSubmit={handleSubmit}
            >
                Are you sure you want to swap the class?
            </CustomAlertDialog>
        </>
    );
};

export default SwapModule;
