import React from 'react';
import Modal from 'react-modal';
import { Box, Button, FormControl, FormLabel, Text, VStack, Divider } from '@chakra-ui/react';

// Ensure you call Modal.setAppElement() in your main entry file (e.g., index.js)
Modal.setAppElement('#root'); // Adjust the selector to your app's root element

const JoinButtonModule = ({ isOpen, onRequestClose }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            ariaHideApp={false}
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    transform: 'translate(-50%, -50%)',
                    padding: '20px',
                    width: '80%',
                    maxWidth: '600px',
                },
            }}
        >
            <Box p={4}>
                <Text fontSize="lg" fontWeight="bold" mb={4}>
                    Join Class
                </Text>
                <Box mb={6}>
                    <Button fontSize="md"  colorScheme="green" >
                        I am available 
                    </Button>
                    
                </Box>
             
            </Box>
            <Box p={4} textAlign="right">
                <Button onClick={onRequestClose} colorScheme="blue">
                    Close
                </Button>
            </Box>
        </Modal>
    );
};

export default JoinButtonModule;
