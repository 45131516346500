import React, { useEffect, useRef, useState } from 'react'

import moment from 'moment';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, FormControl, FormLabel, Input, useToast } from '@chakra-ui/react';

import EditModal from './EditModal'
import { createPlannerExtraClassAction, getPlannerEventRequestListAction, getPlannerListAction, getPlannerScheduleAction, resetPlannerExtraClassStatus } from '../../redux/reducers/planner';
import { getChapterListBySubjectTemplateId } from '../../redux/reducers/courses';
import { STATUS } from '../../App/Constants';

const eventObj = {
    type: "extraClass",
    subject: {
        label: "",
        value: ""
    },
    chapter: {
        label: "",
        value: ""
    },
    topic: "",
    eventDate: "",
    startTime: "",
    endTime: "",
}

const AddExtraClass = ({ show, toggleModal }) => {
    const toast = useToast();
    const dispatch = useDispatch();
    const [eventData, setEventData] = useState({ ...eventObj });
    const [plannerOptions, setPlannerOptions] = useState([]);
    const [selectedPlanner, setSelectedPlanner] = useState(null);
    const [subjectList, setSubjectList] = useState([]);
    const [staffList, setStaffList] = useState([]);
    const [isStaffDisabled, setIsStaffDisabled] = useState(true);
    const [staffOptions, setStaffOptions] = useState([]);
    const [chapterNameList, setChapterNameList] = useState([]);

    const selectedStaff = useRef([]);
    const [currentDate, setCurrentDate] = useState(moment());

    const { user, plannerList, getChapterListBySubjectTemplateIdStatus, chapterKeyValueList, plannerExtraClassData, plannerExtraClassStatus } = useSelector((s) => ({
        user: s.user.user,
        plannerList: s.planner.plannerList,
        plannerExtraClassData: s.planner.plannerExtraClassData,
        plannerExtraClassStatus: s.planner.plannerExtraClassStatus,
        chapterKeyValueList: s.course.chapterKeyValueList,
        getChapterListBySubjectTemplateIdStatus: s.course.getChapterListBySubjectTemplateIdStatus,
    }))


    useEffect(() => {
        dispatch(getPlannerListAction());
    }, [])

    useEffect(() => {
        if (selectedPlanner) {
            let _subjectList = [];
            let _staffList = [];
            _subjectList = selectedPlanner.package.course.map((course) => {
                for (let i = 0; i < course.subject.length; i++) {
                    const subject = course.subject[i];
                    subject.staff.map((staff) => {
                        _staffList.push({
                            ...staff,
                            label: staff.name,
                            value: staff.staffId,
                            subjectId: subject.subjectId,
                            subjectName: subject.subjectName,
                            _subjectId: subject._id
                        })
                    })
                }
                setStaffList(_staffList);
                return course.subject.map((subject) => ({
                    label: subject.subjectName,
                    value: subject.subjectId,
                    courseId: course.courseId
                }))
            })

            // _subjectList = _subjectList.flat();
            // let uniqueEntries = [];
            // let seenValues = new Set();

            // _subjectList.forEach(entry => {
            //     if (!seenValues.has(entry.value)) {
            //         seenValues.add(entry.value);
            //         uniqueEntries.push(entry);
            //     }
            // });
            setSubjectList(_subjectList.flat());
        }
    }, [selectedPlanner]);

    useEffect(() => {
        if (getChapterListBySubjectTemplateIdStatus) {
            setChapterNameList(chapterKeyValueList?.map((e) => ({ value: e?.chapterId?._id, label: e?.chapterId?.name?.en })));
        }
    }, [getChapterListBySubjectTemplateIdStatus])

    useEffect(() => {
        if (plannerList?.length>0) {
            let _plannerList = [...plannerList].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
             _plannerList = _plannerList.map((planner) => ({
                label: planner.plannerTitle,
                value: planner._id
            }))
            setPlannerOptions(_plannerList);
        }
    }, [plannerList])

    useEffect(() => {
        if (plannerExtraClassStatus === STATUS.SUCCESS) {
            toggleModal();
            dispatch(getPlannerScheduleAction({ staffIds: [user?.staff?._id], date: currentDate.format("YYYY-MM-DD") }));
            dispatch(getPlannerEventRequestListAction())
            dispatch(resetPlannerExtraClassStatus())
        }
    }, [plannerExtraClassStatus])

    const handleSelectPlanner = (e) => {
        let _planner = JSON.parse(JSON.stringify(plannerList))
        _planner = _planner.find((planner) => planner._id === e.value);
        let _eventList = _planner.events.map((event) => ({
            ...event,
            key: event._id
        }))
        _planner.events = _eventList;
        setSelectedPlanner(_planner)
    }

    const handleSelect = (e, name) => {
        if (name === "Subject") {
            const _filterSubjectListOptions = staffList.filter((staff) => staff.subjectId === e.value);
            selectedStaff.current = [];
            setStaffOptions(_filterSubjectListOptions)
            setIsStaffDisabled(false);
            setEventData((prev) => ({
                ...prev,
                subjectId: { ...e },
                chapter: {
                    label: "",
                    value: ""
                },
                staff: [
                    {
                        // staffId: "65d077f931e171fd77b60a36",
                        staffId: user?.staff?._id,
                        name: user?.name,
                        default: true
                    }
                ]
            }))
            dispatch(getChapterListBySubjectTemplateId({ courseId: e.courseId, subjectId: e.value }));
        } else if (name === "Chapter") {
            setEventData((prev) => ({
                ...prev,
                chapter: e
            }))
        }
    }

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setEventData((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setEventData((prev) => ({
            ...prev,
            [name]: value
        }))
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (eventData?.subjectId?.value === "" || eventData?.subjectId === undefined) {
            toast({
                title: "Please select subject.",
                position: "top",
                variant: "subtle",
                status: "info",
                duration: 2500,
                isClosable: true,
            });
            return;
        } else if (eventData?.chapter?.value === "") {
            toast({
                title: "Please select chapter.",
                position: "top",
                variant: "subtle",
                status: "info",
                duration: 2500,
                isClosable: true,
            });
            return;
        } else if (eventData?.topic === "") {
            toast({
                title: "Please fill the topic.",
                position: "top",
                variant: "subtle",
                status: "info",
                duration: 2500,
                isClosable: true,
            });
            return;
        } else if (eventData?.eventDate === null || eventData?.eventDate === undefined || eventData?.eventDate === "") {
            toast({
                title: "Please select Date first.",
                position: "top",
                variant: "subtle",
                status: "info",
                duration: 2500,
                isClosable: true,
            });
            return;
        } else if (eventData?.startTime === null || eventData?.startTime === "") {
            toast({
                title: "Please select Start Time.",
                position: "top",
                variant: "subtle",
                status: "info",
                duration: 2500,
                isClosable: true,
            });
            return;
        } else if (eventData?.endTime === null || eventData?.endTime === "") {
            toast({
                title: "Please select end Time.",
                position: "top",
                variant: "subtle",
                status: "info",
                duration: 2500,
                isClosable: true,
            });
            return;
        } else if (moment(`${eventData?.eventDate}T${eventData?.startTime}`).format('YYYY-MM-DD HH:mm') < moment().format('YYYY-MM-DD HH:mm')) {
            toast({
                title: "You cannot select previous Date and Time!!!",
                position: "top",
                variant: "subtle",
                status: "info",
                duration: 2500,
                isClosable: true,
            });
            return;
        } else if (eventData?.startTime > eventData?.endTime) {
            toast({
                title: "Please select End Time greater than Start Time!!!",
                position: "top",
                variant: "subtle",
                status: "info",
                duration: 2500,
                isClosable: true,
            });
            return;
        } else if ((eventData?.startTime) === (eventData?.endTime)) {
            toast({
                title: "Your Start Time is equal to the End Time!!!",
                position: "top",
                variant: "subtle",
                status: "info",
                duration: 2500,
                isClosable: true,
            });
            return;
        }

        let rObj = {
            type: "events",
            plannerId: selectedPlanner?._id,
            events: {
                type: "extraClass",
                subjectId: eventData?.subjectId?.value,
                chapter: eventData?.chapter?.value,
                topic: eventData?.topic,
                imgUrl: "",
                startDate: moment(`${eventData?.eventDate}T${eventData?.startTime}`).format('YYYY-MM-DDTHH:mm'),
                plannerId: selectedPlanner?._id,
                endDate: moment(`${eventData?.eventDate}T${eventData?.endTime}`).format('YYYY-MM-DDTHH:mm'),
                staff: [
                    {
                        staffId: user?.staff?._id,
                        name: user?.name,
                        default: true
                    }
                ]
            }
        }

        // return;
        dispatch(createPlannerExtraClassAction(rObj));
    }

    return (
        <EditModal
            isOpen={show}
            onClose={toggleModal}
            title={"Add Extra Class"}
            onSubmit={handleSubmit}
            // size={"lg"}
            width={1400}
        >
            <FormControl>
                <FormLabel>Planner</FormLabel>
                <Select options={plannerOptions} onChange={handleSelectPlanner} />
            </FormControl>
            <FormControl>
                <FormLabel>Subject</FormLabel>
                <Select options={subjectList} value={eventData?.subjectId} onChange={(e) => handleSelect(e, "Subject")}
                    isDisabled={selectedPlanner === null} />
            </FormControl>
            <FormControl>
                <FormLabel>Chapter</FormLabel>
                <Select options={chapterNameList}
                    value={eventData.chapter}
                    onChange={(e) => handleSelect(e, "Chapter")}
                    isDisabled={getChapterListBySubjectTemplateIdStatus !== STATUS.SUCCESS} />
            </FormControl>
            <FormControl>
                <FormLabel>Topic</FormLabel>
                <Input type="text" name="topic" value={eventData.topic} onChange={handleTextChange} />
            </FormControl>
            {/* <FormControl>
                <FormLabel>Start Date</FormLabel>
                <Input size='md' type='datetime-local' name="startDate" onChange={handleDateChange} />
            </FormControl>
            <FormControl>
                <FormLabel>End Date</FormLabel>
                <Input size='md' type='datetime-local' name="endDate" onChange={handleDateChange} />
            </FormControl> */}
            <FormControl>
                <FormLabel>Select Date</FormLabel>
                <Input size='md' type='date' name="eventDate" onChange={handleDateChange} />
            </FormControl>
            <FormControl>
                <FormLabel>Start Time</FormLabel>
                <Input size='md' type='time' name="startTime" onChange={handleDateChange} />
            </FormControl>
            <FormControl>
                <FormLabel>End Time</FormLabel>
                <Input size='md' type='time' name="endTime" onChange={handleDateChange} />
            </FormControl>
        </EditModal>
    )
}

export default AddExtraClass