import {
    selectPeers,
    selectPeersScreenSharing,
    useHMSStore,
} from "@100mslive/react-sdk";
import Peer from "./Peer";
import { ScreenTile } from "./ScreenTile";

function Conference() {
    const peers = useHMSStore(selectPeers);
    const presenters = useHMSStore(selectPeersScreenSharing);
    const hostObj = peers?.find((peer) => peer.roleName === 'host');
    const guestObj = peers?.find((peer) => peer.roleName === 'guest');
    const peerArr = [];
    if (hostObj) {
        peerArr.push(hostObj);
    }
    if (guestObj) {
        peerArr.push(guestObj);
    }
    return (
        <div className="conference-section">
            <div className="d-flex align-items-center w-100 h-100 customPeerContainer">
                {peerArr.map((peer) => (
                    <Peer key={peer.id} peer={peer} />
                ))}
                {/* {presenters.map((peer) => (
                    <ScreenTile key={"screen" + peer.id} peer={peer} />
                ))} */}
            </div>
        </div>
    );
}

export default Conference;
