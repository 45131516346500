import React, { useEffect, useState } from 'react'
import {
    selectIsConnectedToRoom,
    useHMSActions,
    useHMSStore,
    HMSRoomProvider,
    useVideo,
    useAVToggle,
} from "@100mslive/react-sdk"
import { Box, Button, Flex, Heading } from '@chakra-ui/react'
import "./style.css";
import Header from './Header'
import Footer from './Footer'
import Conference from './Conference'

export default function HMSServiceContent({ roomCode }) {
    const [roomId] = useState(roomCode)
    const hmsActions = useHMSActions()
    const isConnected = useHMSStore(selectIsConnectedToRoom)
    const handleJoin = async () => {
        const authToken = await hmsActions.getAuthTokenByRoomCode({
            roomCode: roomId,
        });
        try {
            await hmsActions.join({
                userName: "Jitendra",
                authToken,
            });
        } catch (e) {
            console.error(e)
        }
    }
    return (
        <div className="p-4">
            {isConnected ? (
                <>
                    {/* <Header /> */}
                    <Conference />
                    <Footer />
                </>
            ) :
                // handleJoin()
                <Button onClick={handleJoin()} style={{ background: "black" }}>
                    {/* Join Now */}
                </Button>
            }
        </div>
    )
}
