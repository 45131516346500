import { useEffect, useState } from "react";
import {
    AudioLevelIcon,
    ExitIcon,
    MicOffIcon,
    MicOnIcon,
    ShareScreenIcon,
    VideoOffIcon,
    VideoOnIcon,
} from "@100mslive/react-icons";
import {
    selectIsLocalAudioPluginPresent,
    selectIsLocalScreenShared,
    selectRoom,
    useAVToggle,
    useHMSActions,
    useHMSStore,
} from "@100mslive/react-sdk";
import { HMSKrispPlugin } from "@100mslive/hms-noise-cancellation";
import { useDispatch, useSelector } from "react-redux";
import { endMentorshipSessionAction } from "../../redux/reducers/mentorship";
import moment from "moment";
import { useCookies } from "react-cookie";

const plugin = new HMSKrispPlugin();

function Footer() {
    const dispatch = useDispatch();
    const [isPluginActive, setIsPluginActive] = useState(false);
    const [roomData, setRoomData] = useState();
    const currentDateTime = moment();
    const { isLocalAudioEnabled, isLocalVideoEnabled, toggleAudio, toggleVideo } = useAVToggle();
    const amIScreenSharing = useHMSStore(selectIsLocalScreenShared);
    const actions = useHMSActions();
    const hmsActions = useHMSActions();
    const room = useHMSStore(selectRoom);
    const isAudioPluginAdded = useHMSStore(
        selectIsLocalAudioPluginPresent(plugin.getName())
    );
    const [cookies, setCookie, removeCookie] = useCookies();

    const { user, mentorshipSessionStartData, mentorshipCurrentSessionData } = useSelector((s) => ({
        user: s.user.user,
        mentorEventsList: s.mentorship.mentorEventsList,
        mentorshipSessionStartData: s.mentorship.mentorshipSessionStartData,
        mentorshipCurrentSessionData: s.mentorship.mentorshipCurrentSessionData,
    }));
    useEffect(() => {
        if (mentorshipSessionStartData && mentorshipSessionStartData?.data?.length > 0) {
            const getMentorData = mentorshipSessionStartData?.data?.filter((data) => data?.role === "host")
            setRoomData(getMentorData[0]);
        }
    }, [mentorshipSessionStartData]);
    const handleLeaveRoom = async () => {
        const formattedDateTime = currentDateTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        hmsActions.leave();
        dispatch(endMentorshipSessionAction(
            {
                role: "teacher",
                id: cookies?.sessionId,
                date: formattedDateTime,
                isCompleted: false
            }
        ));
    }

    const handleExitRome = () => {
        const formattedDateTime = currentDateTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        dispatch(endMentorshipSessionAction(
            {
                role: "teacher",
                id: cookies?.sessionId,
                date: formattedDateTime,
                isCompleted: true,
            }
        ));
        hmsActions.endRoom(true, "End Room");
    }
    return (
        <div className="control-bar">
            <button
                className={`btn-control ${isLocalAudioEnabled ? "" : "highlight"}`}
                onClick={toggleAudio}
            >
                {isLocalAudioEnabled ? <MicOnIcon /> : <MicOffIcon />}
            </button>
            <button
                className={`btn-control ${isLocalVideoEnabled ? "" : "highlight"}`}
                onClick={toggleVideo}
            >
                {isLocalVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
            </button>
            {/* <button
                title="Screenshare"
                className={`btn-control ${amIScreenSharing ? "" : "highlight"}`}
                onClick={() => actions.setScreenShareEnabled(!amIScreenSharing)}
            >
                <ShareScreenIcon />
            </button> */}
            <button
                id="leave-btn"
                className="btn btn-danger d-flex align-items-center"
                onClick={() => handleLeaveRoom()}
            >
                Leave Room
            </button>
            <button
                id="leave-btn"
                className="btn btn-danger d-flex align-items-center"
                onClick={() => handleExitRome()}
            >
                <ExitIcon style={{ marginLeft: "0.25rem" }} className="me-2" /> End Room
            </button>
            {/* {room.isNoiseCancellationEnabled ? (
                <button
                    title="Noise cancellation"
                    className={`btn-control ${isPluginActive ? "" : "highlight"}`}
                    onClick={async () => {
                        if (isAudioPluginAdded) {
                            plugin.toggle();
                            setIsPluginActive((prev) => !prev);
                        } else {
                            await actions.addPluginToAudioTrack(plugin);
                            setIsPluginActive(true);
                        }
                    }}
                >
                    <AudioLevelIcon />
                </button>
            ) : null} */}

        </div>
    );
}

export default Footer;